<template>
  <div class="aplayz_modal">
    <div class="kt_modal_wrap" :style="{ backgroundImage: `url(${this.freePlayerImg})` }">
      <button class="detail_btn align_center" @click="goEvent">
        <!-- 자세히보기
                <img class="arrowforward_img" src="/media/img/curation/arrow_forward_white.png" alt="자세히보기" /> -->
      </button>
      <ul class="close_btn_group dp_flex flex_space_between">
        <li class="dp_flex detail_btn_left flex_justify_center">
          <button class="close_btn" @click="notOpenToday">
            <span class="close_btn_text">
              오늘 하루 보지 않기
            </span>
          </button>
        </li>
        <li class="dp_flex align_center">
          <div class="middle_line"></div>
        </li>
        <li class="detail_btn_right flex_justify_center">
          <button class="close_btn" @click="$emit('close-ktiphone')">
            <span class="close_btn_text">
              닫기
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KtIphonePopUp',
  emits: ['close-ktiphone', 'click-ktiphone'],
  props: {
    freePlayerImg: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    notOpenToday() {
      this.$emit('close-ktiphone', true);
    },
    goEvent() {
      this.$emit('click-ktiphone');
      // this.$store.commit('setEventId', 'event_000035');
      // this.$router.push({ name: 'EventView' }).catch(() => {});
    }
  }
};
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped>
@font-face {
  font-family: 'Pretendard';
  src: url('../../../../public/media/font/pretendard/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.kt_modal_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 268px;
  height: 320px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: contain;
}

.close_btn_group {
  position: absolute;
  bottom: -36px;
  width: 100%;
}

.close_btn {
  font-size: 13px;
  line-height: 27px;
  font-weight: 500;
}

.close_btn_text {
  color: var(--white);
}

.detail_btn {
  opacity: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 14px;
  border-radius: 4px;
  color: var(--white);
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.arrowforward_img {
  display: inline-block;
  margin-left: 10px;
  width: 7px;
  height: 12px;
}

.detail_btn_left {
  width: 235px;
}

.detail_btn_right {
  width: 128px;
}

.middle_line {
  width: 1px;
  height: 12px;
  background-color: var(--white);
}

/* 반응형 */
@media all and (max-width: 768px) {
  .detail_btn_left {
    width: 170px;
  }

  .detail_btn_right {
    width: 90px;
  }
}
</style>
